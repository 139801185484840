import React, { useState } from 'react';
import styled from 'styled-components';
import { Section, Button } from '../styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import {
  Form,
  Input,
  Radio,
} from 'antd';
import step1 from '../images/trade-1.svg'
import step2 from '../images/trade-4.svg'
import step3 from '../images/trade-5.svg'

const { Item } = Form;

const SectionCustom = styled(Section)`
  ::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: ${props => props.theme.primaryColor};
    z-index: -1;
  }
`
const InfoCont = styled.div`
  color: #fff;
`
const StepsList = styled.ul`
  padding: 3rem;
  list-style: none;
  background-color: #fff;
  color: ${props => props.theme.primaryColor};
  margin-top: 2rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .12),
              0px 2px 2px rgba(0, 0, 0, .12),
              0px 4px 4px rgba(0, 0, 0, .12),
              0px 8px 8px rgba(0, 0, 0, .12),
              0px 16px 16px rgba(0, 0, 0, .12);
`
const StepsItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  img{
    width: 3rem;
  }
  span{
    color: initial;
    margin-left: .5rem;
  }
`
const Title = styled.h1`
  font-size: 2rem;
  //text-transform: uppercase;
  color: #fff;
  font-weight: 900;
`
const Subtitle = styled.h2`
  font-size: 2rem;
  color: #fff;
`
const FormCont = styled.div`
  padding: 3rem;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .12),
            0px 2px 2px rgba(0, 0, 0, .12),
            0px 4px 4px rgba(0, 0, 0, .12),
            0px 8px 8px rgba(0, 0, 0, .12),
            0px 16px 16px rgba(0, 0, 0, .12);
`
const FormTitle = styled.p` 
  color: ${props => props.theme.primaryColor};
  
`
const SuccesCont = styled.div`
  min-height: calc(100vh - 126.38px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const SuccessTitle = styled.p`
  font-size: 2rem;
`
const SuccesSubtitle = styled.p`
  
`

export default ()=> {
  const [agent, setAgent] = useState(false);
  const [success, setSuccess] = useState(false);

  const [form] = Form.useForm(null);

  const onFinish = values => {
    console.log(values);
    setSuccess(true);
    /*setTimeout(()=> {
      setSuccess(false);
      form.resetFields();
    }, 5000);*/
  }

  return(
    <SectionCustom first height="100vh">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <InfoCont>
              <Title>
                ¿Te gustaría colaborar con tradeHouse y ser parte de nuestra red de agentes?
              </Title>
              <Subtitle>
                Lo haremos muy fácil, sólo debes seguir los siguientes pasos:
              </Subtitle>
              <StepsList>
                <StepsItem>
                  <img src={step1} alt="" />
                  <span>
                    Completa el formulario
                  </span>
                </StepsItem>
                <StepsItem>
                  <img src={step2} alt="" />
                  <span>
                    Nos pondremos en contacto contigo lo antes posible para acordar como continuar
                  </span>
                </StepsItem>
                <StepsItem>
                  <img src={step3} alt="" />
                  <span>
                    Firmamos una acuerdo y puedes comenzar a ser parte de la red de agentes que esta revolucionando la forma de vender propiedades.
                  </span>
                </StepsItem>                                
              </StepsList>
            </InfoCont>
          </Col>
          <Col xs={12} lg={6}>
            <FormCont>
              {
                success
                ?(
                <SuccesCont>
                  <SuccessTitle>
                    Formulario de aplicación agentes tradeHouse
                  </SuccessTitle>
                  <SuccesSubtitle>
                    Hemos recibido tu mensaje, nos contacactaremos contigo lo antes posible. Equipo tradeHouse
                  </SuccesSubtitle>
                </SuccesCont>                  
                )
                :(
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
              >
                <FormTitle>
                  Formulario de aplicación agentes tradeHouse
                </FormTitle>
                <Item
                  name="fullName"
                  label="Nombre y apellido"
                  rules={[
                    {
                      required: true,
                      message: "Su nombre es requerido"
                    }
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  name="email"
                  label="Correo electrónico"
                  rules={[
                    {
                      required: true,
                      message: "Su correo electrónico es requerido"
                    }
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  name="phone"
                  label="Número de teléfono"
                  rules={[
                    {
                      required: true,
                      message: "Su número de teléfono es requerido"
                    }
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  name="commune"
                  label="Ciudad"
                  rules={[
                    {
                      required: true,
                      message: "Su ciudad es requerida"
                    }
                  ]}
                >
                  <Input />
                </Item>  
                <Item
                  label="¿Eres o has sido agente inmobiliario?"
                >
                  <Radio.Group onChange={e => setAgent(e.target.value)} value={agent}>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Item>      
                {
                  agent && (
                    <Item
                      label="Nombre de la agencia inmobiliaria"
                    >
                      <Input />
                    </Item>
                  )
                }    
                <Item
                  label="Déjanos un mensaje"
                >
                  <Input.TextArea autoSize={{ minRows: 4 }}>
                  </Input.TextArea>
                </Item>           
                <Button type="submit" primary block>
                  Enviar
                </Button>                         
              </Form>
                )
              }
            </FormCont>
          </Col>          
        </Row>
      </Container>
    </SectionCustom>  
  )
}